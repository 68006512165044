<template>
  <page-content>
    <page-title heading="โครงสร้างหน่วยงาน">
      <template v-slot:right>
        <b-button variant="danger" @click="onCreate">เพิ่มหน่วยงาน</b-button>
      </template>
    </page-title>

    <b-row>
      <b-col cols="12">
        <filter-master
          v-model="formfilter"
          col-headquarter="6"
          col-division="6"
          :hiddenInput="{ station: true, school: true, schoolClass: true }"
        >
        </filter-master>
      </b-col>

      <b-col cols="10" md="6" class="mt-2 pr-0">
        <b-form-group class="px-2">
          <b-input-group class="input-group-seamless">
            <b-form-input
              placeholder="ค้นหารายชื่อหน่วยงาน"
              v-model="keyword"
              @keyup.enter="onKeywordSearched"
            ></b-form-input>

            <b-input-group-text slot="prepend">
              <font-awesome-icon class="mx-auto" icon="search" />
            </b-input-group-text>

            <b-input-group-text v-show="keyword.length" slot="append">
              <a href="javascript:void(0)" @click="clearKeyword">
                <font-awesome-icon class="mx-auto" icon="window-close" />
              </a>
            </b-input-group-text>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="2" class="mt-2 pl-0">
        <b-button variant="primary" block size="sm" @click="onKeywordSearched"
          >ค้นหา</b-button
        >
      </b-col>
    </b-row>

    <station-table
      ref="table"
      class="mt-4"
      :fields="fields"
      :params="stationTableParams"
      @edit="onEdit"
      @delete="onDelete"
    />

    <create-or-update-station
      :edit-data.sync="editData"
      v-model="showModalStation"
      @create:success="onCreateSuccess"
    ></create-or-update-station>

    <delete-station
      v-model="showModalDelete"
      @delete:success="onDeleteSuccess"
      :delete-data.sync="deleteData"
    ></delete-station>
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import CreateOrUpdateStation from "../../components/modal/CreateOrUpdateStation";
import StationTable from "../../components/table/Station";
import FilterMaster from "../../components/form/FilterMaster";
import DeleteStation from "../../components/modal/DeleteStation";
import { Auth } from "../../models";

export default {
  components: {
    PageContent,
    PageTitle,
    CreateOrUpdateStation,
    StationTable,
    DeleteStation,
    FilterMaster,
  },

  data() {
    return {
      keyword: "",
      formfilter: {},
      editData: {},
      deleteData: {},
      showModalStation: false,
      showModalDelete: false,
      fields: [
        { key: "m_station_name", label: "สถานี" },
        {
          key: "created_at",
          label: "วันที่สร้าง",
        },
        {
          key: "updated_at",
          label: "แก้ไขล่าสุด",
        },
      ],
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    stationTableParams() {
      const { formfilter } = this;

      return {
        ...formfilter,
      };
    },
  },

  methods: {
    onKeywordSearched() {
      this.$set(this.formfilter, "keyword", this.keyword);
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "keyword");
    },

    onCreate() {
      this.showModalStation = true;
    },

    async onEdit(item) {
      let mHeadquarterId = null;

      if (item.m_division) {
        mHeadquarterId = item.m_division.mHeadquarterId;
      }

      this.editData = { ...item, mHeadquarterId };

      this.showModalStation = true;
    },

    onCreateSuccess() {
      this.$refs.table.refresh();
    },

    onDelete(item) {
      this.deleteData = { ...item };
      this.showModalDelete = true;
    },

    onDeleteSuccess() {
      this.$refs.table.refresh();
    },
  },
};
</script>
